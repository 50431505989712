import refsCommon from "../common/refsCommon";
import { setCookie, getCookie, deleteCookie } from "../common/cookie";

const { acceptBtnCookie, deleteBtnCookie, modal, logo } = refsCommon;

const cookieName = `cookie_${logo.textContent ? logo.textContent.split(" ")[0].trim() : document.domain}`;

const onClickAccept = () => {
	// cookie accept logic
	setCookie(cookieName, "accepted", 1); // зберігаємо кукі 1 день

	modal.classList.remove("active");
};
const onClickDelete = () => {
	// cookie delete logic
	deleteCookie(cookieName); //

	modal.classList.remove("active");
};

window.onload = function () {
	if (getCookie(cookieName) !== "accepted") {
		modal && modal.classList.add("active");
	}
};

acceptBtnCookie && acceptBtnCookie.addEventListener("click", onClickAccept);
deleteBtnCookie && deleteBtnCookie.addEventListener("click", onClickDelete);
