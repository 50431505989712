const refsCommon = {
	// //modals
	acceptBtnCookie: document.getElementById("acceptCookie"),
	deleteBtnCookie: document.getElementById("deleteCookie"),
	modal: document.querySelector(".modal"),
	logo: document.querySelector(".logo"),
	menuToggle: document.getElementById("burgerOpen"),
	menuOverlay: document.getElementById("menuOverlay"),
	closeMenu: document.getElementById("closeMenu"),
	// //sections

	// forms
	form: document.getElementById("form"),

	// arrow up
	arrowUpBtn: document.getElementById("arrowUpBtn"),

	//header
	header: document.getElementById("header"),
};

export default refsCommon;
