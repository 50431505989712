import refsCommon from "../common/refsCommon";
const { menuOverlay, menuToggle, closeMenu } = refsCommon;

document.addEventListener("DOMContentLoaded", () => {
	menuToggle.addEventListener("click", function () {
		menuOverlay.style.display = "flex";
	});

	closeMenu.addEventListener("click", function () {
		menuOverlay.style.display = "none";
	});

	menuOverlay.addEventListener("click", function (e) {
		if (e.target === menuOverlay) {
			menuOverlay.style.display = "none";
		}
	});

	menuOverlay.querySelectorAll("a").forEach((el) => {
		el.addEventListener("click", () => (menuOverlay.style.display = "none"));
	});
});
