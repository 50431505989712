import { fixedHeader } from "./fixed-header";
import { arrowScroll } from "./arrow-up";
import { formValidation } from "./forms";

document.addEventListener("DOMContentLoaded", function () {
	formValidation();
	window.addEventListener("scroll", fixedHeader);
	window.addEventListener("scroll", arrowScroll);
});


